<template>
	<div class="page_content">
		<!-- <a-spin size="large" v-show="loading" /> -->
		<div class="page_edit_content">
			<Form ref="form" :form_data="form_data" @handleSubmit="handleSubmit" @get_select_value="get_select_value">
				<template slot="role_user" v-if="flag">
					<div class="user_number">
						<p class="label_p"><span class="required_icon">*</span>适用人员：</p>
						<TransferTree @change="TransferTreeChange" :treeData.sync="treeData" :value.sync="treeDataValue" />
					</div>
				</template>
				<template slot='hotel' v-if="standards_type=='stay'" >
					<Form ref="form_hotel" :form_data="form_data_hotel"  :class="flag?'':'no_person_mar2'">
						<div slot="interval">
							
							<div class="interval_box">
								<P class='interval_item first_item'><span>区域</span>
									<span>调整方式</span>
									<span>调整金额</span>
								</P>
								<P class='interval_item' v-for='(item,index) in city_manage' :key='index'>
									<a-input v-model='item.manage_id' type='hidden'></a-input>
									<span>{{item.manage_title}}</span>
									<span>
										 <a-radio-group v-model="item.type" >
										      <a-radio :value="'up'">
										        上调
										      </a-radio>
										      <a-radio :value="'down'">
										       下调
										      </a-radio>
										    </a-radio-group>
									</span>
									<span ><a-input v-model="item.price" type='number' required class='price_input'></a-input></span>
								</P>

							</div>
						</div>
						<template slot='interval_tips' >
							<p class="tips_p">
								<a-icon type="info-circle" class='info_icon' />若勾选同住，系统会以两人住一间房的标准生成同住方案，确定预定房间数；若不同住，则预定酒店房间数为出差人数。
							</p>
						</template>
						<template slot='price'>
						<div class="user_number">
							<p class="label_p"><span class="required_icon">*</span>酒店价格：</p>
							<p><a-input v-model="price2" type='number' required class='price_input' placeholder='请输入了预定的酒店价格'></a-input>元以下</p>
						</div>
						</template>
					
					</Form>
				</template>
				<template slot='subsidy' v-if="standards_type=='subsidy'" >
					<Form ref="form_subsidy" :form_data="form_data_subsidy" @handleSubmit="handleSubmit1"  :class="flag?'':'no_person_mar'"
						@handleChange="handleChange1">
						<div slot="subsidy_standard">
							<div class="interval_box">
								<P class='interval_item first_item'><span>区域</span>
									<span>调整金额</span>
								</P>
								<P class='interval_item' v-for='(item,index) in subsidy' :key='index'>
									<a-input v-model='item.manage_id' type='hidden'></a-input>
									<span>{{item.manage_title}}</span>
									<span ><a-input v-model="item.price" type='number' required class='price_input'></a-input>元/天</span>
								</P>
							</div>
						</div>
						<template slot="day">
							<div>
								<p>出差时长大于 <a-input v-model="day" type='number' required class='price_input'></a-input>天</p>
								<p class="tips_p price_input_tips">
										<a-icon type="info-circle" class='info_icon' />当出差时长大于设置天数时，系统自动判定为驻外，并套用下述此标准
									</p>
							</div>
						</template>
						<div slot="abroad_standard">
							<div class="interval_box">
								<P class='interval_item first_item'><span>区域</span>
									<span>调整金额</span>
								</P>
								<P class='interval_item' v-for='(item,index) in abroad' :key='index'>
									<a-input v-model='item.manage_id' type='hidden'></a-input>
									<span>{{item.manage_title}}</span>
									<span ><a-input v-model="item.price" type='number' required class='price_input'></a-input>元/天</span>
								</P>
							
							</div>
						</div>
					</Form>
				</template>
				<template slot='traffic' v-if="standards_type=='traffic'">
					<Form ref="form_traffic" :form_data="form_data_traffic"  :class="flag?'':'no_person_mar'"  @handleSubmit="handleSubmit1"
						@handleChange="handleChange1">
						<template slot="price">
							每日 <a-input v-model="price" type='number' 	placeholder= "请输入标准名称" required class='price_input traffic_input'></a-input>元以内
						</template>
					</Form>
				</template>
			</Form>
		</div>
		<!-- <EditPop @handleSubmit="submit_hotel" :form_data="form_data_add" :visible.sync="visible_data_add">
			<template slot="area">
				<div class="user_number mask_input">
					<p class="label_p"><span class="required_icon">*</span>选择区域：</p>
				<a-select :default-value="hotel_area_data" mode="multiple" placeholder='点击选择调整地区' @change="handleChange_area">
					<a-select-opt-group v-for='(area,index) in getArea_data' key='1' >
						<span slot="label">{{area.label}}</span>
						<a-select-option :value="area_item.label" :key='index' v-for='area_item in area.children '>
							{{area_item.label}}
						</a-select-option>
					</a-select-opt-group>
				</a-select>
				</div>
			</template>
		</EditPop> -->
		<!-- <EditPop @handleSubmit="submit_subsidy1" :form_data="form_subsidy_add1" :visible.sync="visible_data_subsidy1">
			<template slot="area">
				<div class="user_number mask_input">
					<p class="label_p"><span class="required_icon">*</span>选择区域：</p>
				<a-select :default-value="area_data1" mode="multiple" @change="handle_area1" placeholder='点击选择调整地区'>
					<a-select-opt-group v-for='(area,index) in getArea_data' key='1' >
						<span slot="label">{{area.label}}</span>
						<a-select-option :value="area_item.label" :key='index' v-for='area_item in area.children '>
							{{area_item.label}}
						</a-select-option>
					</a-select-opt-group>
				</a-select>
				</div>
			</template>
		</EditPop> -->
		<!-- <EditPop @handleSubmit="submit_subsidy2" :form_data="form_subsidy_add2" :visible.sync="visible_data_subsidy2">
			<template slot="area">
				<div class="user_number mask_input">
					<p class="label_p"><span class="required_icon">*</span>选择区域：</p>
			<a-select-opt-group v-for='(area,index) in getArea_data' key='1' >
				<span slot="label">{{area.label}}</span>
				<a-select-option :value="area_item.label" :key='index' v-for='area_item in area.children '>
					{{area_item.label}}
				</a-select-option>
			</a-select-opt-group>
				</div>
			</template>
		</EditPop> -->
	</div>
</template>

<script>
	import Form from "@/components/Form";
	import SelectCustomer from "@/components/SelectCustomer";
	import EditPop from "@/components/EditPop";
	import TransferTree from "@/components/TransferTree";
	import { post_user } from '@/api/api'
	import {
		getUserToRole,
		getHotelSelect,
		saveTripStandard,
		tripStandardDetail,
		getArea
	} from "@/api/evection.js";

	export default {
		components: {
			Form,
			EditPop,
			SelectCustomer,
			TransferTree
		},
		data() {
			return {
				price2:0,
				city_manage:[],
				abroad:[],
				visible_data_company:false,
				area_data1: [],
				area_data2: [],
				hotel_area_data: [],
				role_user: '',
				expand: false,
				page: 1,
				pagination: {
					...this.$config.pagination
				},
				list: [],
				price: 0,
				day: 0,
				treeData: [],
				subsidy_data1: [],
				subsidy_data2: [],
				subsidy_standard1: [],
				subsidy_standard2: [],
				visible_data_add: false,
				visible_data_subsidy1: false,
				visible_data_subsidy2: false,
				treeDataValue: [],
				loading: true,
				standards_type: 'stay',
				subsidy:[],
				form_data_subsidy: {
					title: "补贴标准",
					show_submit_btn: false,
					show_close_btn: false,
					close_reset_btn: false,
					list: [{
							type: "slot",
							name: "subsidy_standard",
							title: "补贴标准",
							options: {
								initialValue: ''
							},
							list: []
						},
						{
							type: "slot",
							name: "day",
							title: "驻外标准时长",
							options: {
								initialValue: ''
							},
							list: []
						},
						{
							type: "slot",
							name: "abroad_standard",
							title: "驻外补贴标准",
							options: {
								initialValue: ''
							},
							list: []
						}
					]
				},
				form_data_traffic: {
					title: "",
					show_submit_btn: false,
					show_close_btn: false,
					close_reset_btn: false,
					list: [{
							type: "checkbox",
							name: "traffic_standard",
							title: "交通标准",
							required: true,
							placeholder: "请选择交通标准",
							options: {
								rules: [{
									required: true,
									message: "请选择交通标准"
								}],
								initialValue: []
							},
							list: []
						},
						{
							type: "checkbox",
							name: "urgent_standard",
							title: "急办公务交通标准",
							options: {
								initialValue: []
							},
							list: []
						},

						{
							type: "slot",
							name: 'price',
							title: "打车报销",
							options: {
								initialValue: ''
							},
							list: []
						},
					]
				},
				form_data_hotel: {
					title: "",
					show_submit_btn: false,
					show_close_btn: false,
					close_reset_btn: false,
					list: [{
							type: 'slot',
							name: 'price',
							title: '',
								placeholder: "请输入酒店金额",
							options: {
								rules: [{
									required: true,
									message: "请输入酒店金额"
								}],
								initialValue: 0
							},
						},
						{
							type: "slot",
							name: "interval",
							title: "标准上浮/下调",
							mode: "default",
							options: {
								initialValue: ""
							},
							list: []
						},
						{
							type: "radio",
							name: "live_together",
							title: "同性别是否同住",
							options: {
								initialValue: 1
							},
							list: [{
									key: 1,
									value: "是"
								},
								{
									key: 0,
									value: "否"
								}
							]
						},
						{
							type: "slot",
							name: "interval_tips",
						},

					]
				},
				all_data: [],
				hotel_data: [
					{
						city:'一线城市',
						type:'1',
						price:'0'
					},
					{
						city:'二线城市',
						type:'1',
						price:'0'
					},
					{
						city:'三线城市',
						type:'1',
						price:'0'
					},
					{
						city:'四线城市',
						type:'1',
						price:'0'
					}
				],
				form_data_add: {
					title: "添加区域标准上浮/下调",
					show_submit_btn: false,
					show_close_btn: false,
					close_reset_btn: false,
					list: [{
							type: "slot",
							name: "area",
							title: "",
							options: {
								rules: [{
									required: true,
									message: "请选择区域"
								}],
								initialValue: ""
							}
						},
						{
							type: "radio",
							name: "type",
							title: "调整方式",
							relation_name: 'close_desc',
							mode: "default",
							options: {
								rules: [{
									required: true,
									message: ""
								}],
								initialValue: "down"
							},
							list: [{
									key: "down",
									value: "下调"
								},
								{
									key: "up",
									value: "上浮"
								},
							]
						},
						{
							type: 'number',
							name: 'price',
							title: '调整金额',
								placeholder: "请输入金额",
							options: {
								rules: [{
									required: true,
									message: "请输入金额"
								}],
								initialValue: 0
							},
						},
					]
				},
					flag:1,
				form_data: {
					title: "添加标准",
					show_submit_btn: true,
					show_close_btn: true,
					close_reset_btn: true,
					list: [{
							type: "text",
							name: "title",
							title: "标准名称",
							placeholder: "请填写标准名称，例如：总部住宿标准",
							options: {
								rules: [{
									required: true,
									message: "请填写标准名称，例如：总部住宿标准"
								}],
								initialValue: ""
							}
						},
						{
							type: "select",
							name: "type",
							title: "标准类型",
							mode: "default",
							options: {
								rules: [{
									required: true,
									message: "请选择标准类型"
								}],
								initialValue: "stay"
							},
							list: [{
									key: 'stay',
									value: "住宿标准"
								},
								{
									key: 'traffic',
									value: "交通标准"
								},
								{
									key: 'subsidy',
									value: "补贴标准"
								},
							]
						},
						{
							type: "slot",
							name: "role_user",
							title: "",
							options: {
								rules: [{
									required: true,
									message: "请选择适用人员"
								}],
								initialValue: ""
							},
						},
						{
							type: "slot",
							name: "hotel",
							title: "",
							options: {
								rules: [{
									required: true,
									message: "请选择适用人员"
								}],
								initialValue: ""
							},
						},
						{
							type: "slot",
							name: "traffic",
							title: "",
							options: {
								rules: [{
									required: true,
									message: "请选择适用人员"
								}],
								initialValue: ""
							},
						},
						{
							type: "slot",
							name: "subsidy",
							title: "",
							options: {},
							},
							],
							},
					
				
				getArea_data: []
			};
		},
		async created() {
			post_user().then(res => {
                this.treeData = this.$method.get_user_department_list(res.data.list)
                   if (this.$route.query.id) {
                      this.get_info()
                   }
            })
			// getUserToRole().then(res => {
			// 	this.treeData = this.$method.get_user_department_list(res.data.role);
			// 	if (this.$route.query.id) {
			// 		this.get_info()
			// 	}
			// })
			this.get_HotelSelect()
		},
		methods: {
			
			del_hotel(type, index) {
				if (type == 1) {
					this.hotel_data.splice(index, 1);
				} else if (type == 2) {
					this.subsidy_standard1.splice(index, 1);
				} else {
					this.subsidy_standard2.splice(index, 1);
				}

			},
			handleChange_area(data) {
				this.hotel_area_data = data;
			},
			handle_area1(data) {

				this.area_data1 = data;
			},
			handle_area2(data) {
				this.area_data2 = data;
			},
			get_getArea() {
				getArea().then(res => {
					this.getArea_data = res.data.list
				})
			},
			get_info() {
				tripStandardDetail({
					data: {
						id: this.$route.query.id
					}
				}).then(res => {
					this.all_data = res.data.list
					this.standards_type = res.data.list.type;
					this.flag = res.data.list.flag
					let treeDataValue = [];
					let role_data = []
					res.data.list.role_user.map(io => {
						treeDataValue.push("user_" + io.id)
						io.key = "user_" + io.id;
						io.title = io.username;
						role_data.push(io.key)
						return io;
					});

					this.treeDataValue = treeDataValue;
					this.role_user = role_data.join(',')
					this.$refs.form.setFieldsValue({
					    role_user: treeDataValue.join(',')
					});
					this.$refs.form.setFieldsValue({
						title: res.data.list.title
					});
					this.$refs.form.setFieldsValue({
						type: res.data.list.type
					});
					if (this.standards_type == 'stay') {
						this.price2= res.data.list.price
						this.city_manage=res.data.list.interval
						this.$refs.form_hotel.setFieldsValue({
							live_together: res.data.list.live_together
						});

					}

					if (this.standards_type == 'subsidy') {
						this.abroad=res.data.list.abroad_standard
						this.subsidy=res.data.list.subsidy_standard
						this.day = res.data.list.day
						
					}
					if (this.standards_type == 'traffic') {
						this.price = res.data.list.price;
						let data = res.data.list.urgent_standard.split(',')
						let data2 = res.data.list.traffic_standard.split(',')
						this.form_data_traffic.list.forEach(item => {
							if (item.name == 'urgent_standard') {
								data.map(io => {
									item.options.initialValue.push(Number(io));
								});
							}
							if (item.name == 'together_standard') {
									item.options.initialValue=res.data.list.together_standard
							}
							if (item.name == 'traffic_standard') {
								data2.map(io => {
									item.options.initialValue.push(Number(io));
								});
							}
						})
					}
				})
			
			},
			add_hotel() {
				this.visible_data_add = true;
			},
			add_subsidy_standard() {
				this.visible_data_subsidy1 = true;
			},
			add_subsidy_standard2() {
				this.visible_data_subsidy2 = true;
			},

			submit_hotel(data) {
				if(this.hotel_area_data.length<=0){
					  this.$message.error('请选择区域');
					return false;
					
				}
				data.values.area = this.hotel_area_data.join(',')
				this.hotel_data.push(data.values)
				this.visible_data_add = false;
				this.hotel_area_data = []
			},
			submit_subsidy1(data) {
				if(this.area_data1.length<=0){
					  this.$message.error('请选择区域');
					return false;
					
				}
				data.values.area = this.area_data1.join(',')
				this.subsidy_standard1.push(data.values)
				this.visible_data_subsidy1 = false;
			},
			submit_subsidy2(data) {
				if(this.area_data2.length<=0){
					  this.$message.error('请选择区域');
					return false;
					
				}
				data.values.area = this.area_data2.join(',')
				this.subsidy_standard2.push(data.values)
				this.visible_data_subsidy2 = false;
			},
			get_HotelSelect() {
				getHotelSelect().then(res => {
					res.data.traffic = res.data.traffic.map(item => {
						item.key = item.value;
						item.value = item.label;
						return item;
					});
					this.abroad= res.data.abroad
					this.subsidy= res.data.subsidy
					this.city_manage= res.data.city_manage
					this.form_data_traffic.list.forEach(item => {
						if (item.name == "traffic_standard") {
							item.list = res.data.traffic;
						}
						if (item.name == "urgent_standard") {
							item.list = res.data.traffic;
						}

					});
					

				})
			},
		
			TransferTreeChange(value) {
				this.role_user = value.join(',')
			},
			add_table() {
				this.visible_data_add = true;
			},

			get_select_value(data) {
				this.standards_type = data
			},
			save_trip(data) {
				saveTripStandard({
					data: data,
					info: true
				}).then(res => {
					this.$router.go(-1);
				})
			},
			handleChange1(e) {
				
			},
			handleSubmit1(e) {
				
			},
			handleSubmit(e) {
				let obj = {
					id: this.$route.query.id,
					...e.values,
					role_user: this.role_user
				}
				if (e.values.type == "stay") {
					this.$refs.form_hotel.submit().then(res => {
						obj.price = this.price2;
						obj.live_together = res.live_together;
						obj.interval = JSON.stringify(this.city_manage)
						obj.together_standard = res.together_standard;
						this.save_trip(obj)
					})
				}
				if (e.values.type == "traffic") {
					this.$refs.form_traffic.submit().then(res => {
						obj.together_standard = res.together_standard;
						obj.traffic_standard = res.traffic_standard.join(',');
						obj.urgent_standard = res.urgent_standard.join(',');
						obj.price = this.price
						this.save_trip(obj)
					})

				}

				if (e.values.type == "subsidy") {
					this.$refs.form_subsidy.submit().then(res => {
						obj.subsidy_standard = JSON.stringify(this.subsidy);
						obj.abroad_standard = JSON.stringify(this.abroad);
						obj.day = this.day
						this.save_trip(obj)
					})

				}

			}
		}
	};
</script>
<style lang="less" scoped>
	.map_select {
		position: relative;

		.serach_input {
			position: absolute;
			top: 20px;
			left: 20px;
		}
	}

	ul {
		padding: 0;
	}

	li {
		display: block;
		list-style: none;
		display: flex;

		span {
			padding-right: 10px;

			display: inline-block;
		}
	}

	.avatar-uploader>.ant-upload {
		width: 128px;
		height: 128px;
	}

	.ant-upload-select-picture-card i {
		font-size: 32px;
		color: #999;
	}

	.ant-upload-select-picture-card .ant-upload-text {
		margin-top: 8px;
		color: #666;
	}

	.spin-content {
		border: 1px solid #91d5ff;
		background-color: #e6f7ff;
		padding: 30px;
	}

	.ant-form-item-control {
		width: 52%;
	}

	.price_input {
		width: 200px;
	}

	.interval_box {
		width: 660px;
		text-align: center;
		
		.interval_item {
			width: 100%;
			display: flex;
			border: 1px solid #eee;
			border-top: none;

			span {
				flex: 1;
			}

		}

		.first_item {
			background: #eee;
		}
	}
	.interval_box2 {
		margin-left:21%;
	}

	.del_hotel {
		color: #1890ff;
	}
	
	.user_number {
		margin: 0 auto;
		// margin-left: 15.5%;
		display: flex;
		color: #000;
	}
	.user_number2 {
		// margin-left: 15.5%;
	}
	.required_icon {
		color: red;
		display: inline-block;
		margin-right: 5px;
	}
	.label_p {
		margin-right:8px;
		width: 20%;
		text-align: right;
	}
		
	.mask_input{
		margin-left: 10px;
		.label_p {
			width: 105px;
			margin-right:0px;
		}
	}
	.tips_p {
		margin-top: -30px;
		margin-left: 20%;
		.info_icon {
			margin-right: 5px;
		}
	}
	.interval_info{
		    margin-left:180px;
		    margin-top: -40px;
	}
	.interval_standards_type {
		  margin-left:20px;
		  margin-top: 0px;
		  color: rgba(0, 0, 0, 0.65);
	}
	.price_input_tips {
		margin-left:350px;
		margin-top: -40px;
	}
	.traffic_input {
		margin-top: -6px;
	}
	.no_person_mar {
		margin-top: -60px;
	}
	
	.no_person_mar2 {
		margin-top: -25px;
	}
</style>
